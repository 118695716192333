'use client';

import { MindfulAudioImg } from '@/config/img/mindfulAudio';
import { useClientTranslation } from '@/hook/translate';
import { Image } from '@nextui-org/react';




export default function Footer() {
  const { t } = useClientTranslation();
  return (
    <>
      <div className='footer max-md:hidden bg-[#F7F8FA] w-full py-16 px-[150px]'>
          <div className='flex justify-center items-center'>
            <div className='description-wrap w-1/2'>
              <div className="img-wrap">
                <Image src={ MindfulAudioImg.Logo } alt='logo'  />
              </div>
              <p className='pt-3 text-18-24 font-normal'>{ t('footerTxt', 'mindfulAudio') }</p>
              <p className='text-left pt-[10px] text-[rgba(0,0,0,0.65)] text-14-19'>{ t('contact') }: onebookreads@gmail.com </p>
            </div>
            <div className='w-1/2 flex justify-center'>
              <Image src={ MindfulAudioImg.Footer } alt='picture' />
            </div>
          </div>
      </div>
      <div className='hidden max-md:block px-[15px] bg-[#F7F8FA] py-8'>
        <div className="img-wrap">
          <Image src={ MindfulAudioImg.Logo } alt='logo' className='w-auto' />
        </div>
        <p className='pt-4 text-14-19 font-normal'>{ t('footerTxt', 'mindfulAudio') }</p>
        <p className='text-left pt-[10px] text-[rgba(0,0,0,0.65)] text-12-17'>{ t('contact') }: onebookreads@gmail.com </p>
      </div>
    </>
  );
}
