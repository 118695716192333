'use client';
import { TbMenu2 } from 'react-icons/tb';
import { useClientTranslation } from '@/hook/translate';
import {
  // Dropdown, 
  // DropdownTrigger,
  // DropdownMenu,
  // DropdownItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Image,
} from '@nextui-org/react';
import { useActivePath } from '@/hook';
import { useParams } from 'next/navigation';
import { useState, useMemo } from 'react';
import clsx from 'clsx';
import { MindfulAudioImg } from '@/config/img/mindfulAudio';
import { dealLng } from '@/utils';
import Link from 'next/link';

export default function SmHeader() {
  const { t } = useClientTranslation();


  const activePath = useActivePath(['bookmagazine', 'collection', 'pdf']);

  const params = useParams();
  const isHome = params?.category?.length > 0;
  const [isOpen, setIsOpen] = useState(false);
  const languageList = [
    {
      text: 'English',
      value: 'en',
    },
    {
      text: 'Español',
      value: 'es',
    }, {
      text: 'Français',
      value: 'fr',
    }, {
      text: 'Português',
      value: 'pt',
    }, {
      text: 'Deutsch',
      value: 'de',
    },
  ];

  const lng = (params.lng as string) || 'en';

  const isShowLangChange = useMemo(() => {
    if (params?.pdfid || params?.category) {
      return true;
    }
    return false;
  }, [params]);

  const getHomePath = (langCode: string): string => {
    if (params?.pdfid) {
      return `${dealLng(langCode)}/pdf/1`;
    }
    if (params?.category) {
      return `${dealLng(langCode)}` || '/';
    }
    return '/';
  };

  // const goBookMagazine = useCallback(() => {
  //   const lng = pathname.split('/').filter(item => item && item !== '/')[0];
  //   router.push('/' + lng + '/bookmagazine/list/1/');
  // }, [pathname, router]);

  // const goCollection = useCallback(() => {
  //   const lng = pathname.split('/').filter(item => item && item !== '/')[0];
  //   router.push('/' + lng + '/collection/');
  // }, [pathname, router]);
  return (
    <div className={clsx('h-[50px] flex items-center px-[15px]', isHome ? 'bg-[#E7FBB0]' : 'bg-[#FAFAFA]')}>
      <Link href={ `${dealLng(lng) || '/'}` }>
        <Image src={MindfulAudioImg.Logo} alt='logo' width={140} height={29} />
      </Link>
      <div className='flex-1 flex justify-end left-0'>
        <Popover
          classNames={{
            content: 'bg-transparent relative w-screen rounded-none',
          }}
          placement='bottom-start'
        >
          <PopoverTrigger>
            <div className='flex items-center'>
              <TbMenu2 className='w-6 h-6 text-[#6bcefa]' />
            </div>
          </PopoverTrigger>
          <PopoverContent >
            <div className="lang-list w-screen py-5 absolute top-0 -left-3 text-center bg-white text-18-27">
              {/* <div
                className={
                  clsx(activePath[0] ? 'text-[#3AD1FF]' : 'text-white', 'cursor-pointer')
                }
                onClick={goBookMagazine}
              >
                {t('bookMagazine', 'listenBriefs')}
              </div> */}
              {/* <div
                className={
                  clsx(activePath[1] ? 'text-[#3AD1FF]' : 'text-white', 'mt-5 cursor-pointer')
                }
                onClick={goCollection}
              >
                {t('collection', 'listenBriefs')}
              </div> */}
              <div
                className={
                  clsx(activePath[2] ? 'text-[#3AD1FF]' : 'text-black', ' cursor-pointer')
                }
                
              >
                <Link href={`${dealLng(lng)}/pdf/1`}>
                PDF
                </Link>
              </div>
              <div className={ clsx(' mt-5 flex justify-center items-center cursor-pointer', isShowLangChange ? '' : 'hidden') } onClick={() => setIsOpen(!isOpen)}>
                <span className='pr-1'>{t('language')}</span>
                <Image src={MindfulAudioImg.ArrowUp} className={clsx(isOpen ? 'block' : 'hidden')} alt='arrow' width={16} height={16} />
                <Image src={MindfulAudioImg.arrowDown} className={clsx(!isOpen ? 'block' : 'hidden')} alt='arrow' width={16} height={16} />
              </div>
              <div className={clsx(isOpen ? 'block' : 'hidden')}>
                {
                  languageList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          clsx('lang-item mt-[15px] first:mt-[10px]  cursor-pointer', lng === item.value ? 'text-[#3AD1FF]' : 'text-black')
                        }
                        // onClick={() => {
                        //   if (item.value === lng) return;
                        //   handleClick(item.value);
                        // }}
                      >
                        <Link href={ getHomePath(item.value) }>
                        {item.text}
                        </Link>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}
