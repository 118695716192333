'use client';

import { Popover, PopoverTrigger, PopoverContent, Image } from '@nextui-org/react';
import { useClientTranslation } from '@/hook/translate';
import { useParams } from 'next/navigation';
import clsx from 'clsx';
import { useState } from 'react';
import { ListenBriefImg } from '@/config/img/listenbiref';
import { dealLng } from '@/utils';
import Link from 'next/link';

export default function LanguageChange() {
  const { t } = useClientTranslation();
  const params = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const languageList = [
    {
      text: 'English',
      value: 'en',
    },
    {
      text: 'Español',
      value: 'es',
    }, {
      text: 'Français',
      value: 'fr',
    }, {
      text: 'Português',
      value: 'pt',
    }, {
      text: 'Deutsch',
      value: 'de',
    },
  ];
  const lng = (params.lng as string) || 'en';

  // const handleClick = (value: string) => {
  //   let newPath = path.replace(lng, value);

  //   if (params?.pdfid) {
  //     newPath = `${dealLng(value)}/pdf/1`;
  //   }
  //   if (params?.category) {
  //     newPath = `${dealLng(value)}/home/all/all/1`;
  //   }
  //   router.push(newPath);
  // };

  const getHomePath = (langCode: string): string => {
    if (params?.pdfid) {
      return `${dealLng(langCode)}/pdf/1`;
    }
    if (params?.category) {
      return `${dealLng(langCode)}` || '/';
    }
    return '/';
  };

  
  return (
    <div className={ clsx('text-20-27 font-bold h-full flex items-center cursor-pointer', params?.bookid ? 'hidden' : '') }>
      <Popover 
        classNames={{
          content: 'bg-white py-5',
        }}
        onOpenChange={(openStatus) => setIsOpen(openStatus)}
      >
        <PopoverTrigger>
          <div className='flex items-center'>
            <span className='pr-1'>{t('language', 'common')}</span>
              <Image src={ ListenBriefImg.ArrowUp } className={clsx( isOpen ? 'block' : 'hidden' )} alt='arrow' width={ 16 } height={ 16 } />
              <Image src={  ListenBriefImg.arrowDown } className={clsx( !isOpen ? 'block' : 'hidden' )} alt='arrow' width={ 16 } height={ 16 } />
          </div>
        </PopoverTrigger>
        <PopoverContent >
          <div className="lang-list w-[140px] text-center text-16-22">
            {
              languageList.map((item, index) => {
                return (
                  <div 
                    key={index} 
                    className={
                      clsx('lang-item mt-[15px] first:mt-0 cursor-pointer', lng === item.value ? 'text-[#3AD1FF]' : 'text-black')
                    }
                    // onClick={() => {
                    //   if (item.value === lng) return;
                    //   handleClick(item.value);
                    // }}
                  >
                    <Link href={ getHomePath(item.value) }>{item.text}</Link>
                  </div>
                );
              })
            }
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
