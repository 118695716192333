const prefix: string = process.env.NEXT_PUBLIC_MINDFUL_AUDIO_IMG_ADDRESS || '';
export const MindfulAudioImg =   { 
  Logo : prefix + '/logo.png',
  Footer : prefix + '/footer.png',
  HeaderImage : prefix + '/header-image.png',
  HeaderEar : prefix + '/header-ear.png',
  HeaderBook : prefix + '/header-book.png',
  PlayAudio : prefix + '/play-audio.png',
  playDetail : prefix + '/play-detail.png',
  BookeyLogo : prefix + '/bookey-logo.png',
  Code : prefix + '/code.png',
  GooglePlayBadge : prefix + '/google-play-badge.png',
  AppStore : prefix + '/app-store.png',
  LeftArrow : prefix + '/left-arrow.png',
  RightArrow : prefix + '/right-arrow.png',
  Star : prefix + '/star.png',
  DetailPlay : prefix + '/detail-play.png',
  DetailStop : prefix + '/detail-stop.png',
  Lock : prefix + '/lock.png',
  Triangle : prefix + '/triangle.png',
  ArrowUp: prefix + '/arrow-up.png',
  arrowDown: prefix + '/arrow-down.png',
  Play: prefix + '/play.png',
  Stop: prefix + '/stop.png',
  PlayBookey: prefix + '/play-bookey.png',
  StopBookey: prefix + '/stop-bookey.png',
  ReaderDown: prefix + '/reader-down.png',
};
